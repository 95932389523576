"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteFooterContext = exports.GdocsContentSource = exports.OwidArticlePublicationContext = exports.AxisAlign = exports.VerticalAlign = exports.HorizontalAlign = exports.Position = exports.DimensionProperty = exports.DeployStatus = exports.JsonError = exports.SuggestedChartRevisionStatus = exports.WP_BlockType = exports.WP_BlockClass = exports.WP_ColumnStyle = exports.GraphType = exports.GraphDocumentType = exports.WP_PostType = exports.SubNavId = exports.BLOCK_WRAPPER_DATATYPE = exports.ScaleType = exports.SortBy = exports.SortOrder = exports.IDEAL_PLOT_ASPECT_RATIO = exports.EPOCH_DATE = void 0;
// todo: remove when we ditch Year and YearIsDay
exports.EPOCH_DATE = "2020-01-21";
/** The "plot" is a chart without any header, footer or controls */
exports.IDEAL_PLOT_ASPECT_RATIO = 1.8;
// TODO: remove duplicate definition, also available in CoreTable
var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var SortBy;
(function (SortBy) {
    SortBy["custom"] = "custom";
    SortBy["entityName"] = "entityName";
    SortBy["column"] = "column";
    SortBy["total"] = "total";
})(SortBy = exports.SortBy || (exports.SortBy = {}));
var ScaleType;
(function (ScaleType) {
    ScaleType["linear"] = "linear";
    ScaleType["log"] = "log";
})(ScaleType = exports.ScaleType || (exports.ScaleType = {}));
exports.BLOCK_WRAPPER_DATATYPE = "block-wrapper";
var SubNavId;
(function (SubNavId) {
    SubNavId["about"] = "about";
    SubNavId["biodiversity"] = "biodiversity";
    SubNavId["coronavirus"] = "coronavirus";
    SubNavId["co2"] = "co2";
    SubNavId["energy"] = "energy";
    SubNavId["forests"] = "forests";
    SubNavId["water"] = "water";
    SubNavId["explorers"] = "explorers";
})(SubNavId = exports.SubNavId || (exports.SubNavId = {}));
var WP_PostType;
(function (WP_PostType) {
    WP_PostType["Post"] = "post";
    WP_PostType["Page"] = "page";
})(WP_PostType = exports.WP_PostType || (exports.WP_PostType = {}));
var GraphDocumentType;
(function (GraphDocumentType) {
    GraphDocumentType["Topic"] = "topic";
    GraphDocumentType["Article"] = "article";
})(GraphDocumentType = exports.GraphDocumentType || (exports.GraphDocumentType = {}));
var GraphType;
(function (GraphType) {
    GraphType["Document"] = "document";
    GraphType["Chart"] = "chart";
})(GraphType = exports.GraphType || (exports.GraphType = {}));
var WP_ColumnStyle;
(function (WP_ColumnStyle) {
    WP_ColumnStyle["StickyRight"] = "sticky-right";
    WP_ColumnStyle["StickyLeft"] = "sticky-left";
    WP_ColumnStyle["SideBySide"] = "side-by-side";
})(WP_ColumnStyle = exports.WP_ColumnStyle || (exports.WP_ColumnStyle = {}));
var WP_BlockClass;
(function (WP_BlockClass) {
    WP_BlockClass["FullContentWidth"] = "wp-block-full-content-width";
})(WP_BlockClass = exports.WP_BlockClass || (exports.WP_BlockClass = {}));
var WP_BlockType;
(function (WP_BlockType) {
    WP_BlockType["AllCharts"] = "all-charts";
})(WP_BlockType = exports.WP_BlockType || (exports.WP_BlockType = {}));
var SuggestedChartRevisionStatus;
(function (SuggestedChartRevisionStatus) {
    SuggestedChartRevisionStatus["pending"] = "pending";
    SuggestedChartRevisionStatus["approved"] = "approved";
    SuggestedChartRevisionStatus["rejected"] = "rejected";
    SuggestedChartRevisionStatus["flagged"] = "flagged";
})(SuggestedChartRevisionStatus = exports.SuggestedChartRevisionStatus || (exports.SuggestedChartRevisionStatus = {}));
// Exception format that can be easily given as an API error
class JsonError extends Error {
    constructor(message, status) {
        super(message);
        this.status = status || 400;
    }
}
exports.JsonError = JsonError;
var DeployStatus;
(function (DeployStatus) {
    DeployStatus["queued"] = "queued";
    DeployStatus["pending"] = "pending";
    // done = "done"
})(DeployStatus = exports.DeployStatus || (exports.DeployStatus = {}));
var DimensionProperty;
(function (DimensionProperty) {
    DimensionProperty["y"] = "y";
    DimensionProperty["x"] = "x";
    DimensionProperty["size"] = "size";
    DimensionProperty["color"] = "color";
    DimensionProperty["table"] = "table";
})(DimensionProperty = exports.DimensionProperty || (exports.DimensionProperty = {}));
var Position;
(function (Position) {
    Position["top"] = "top";
    Position["right"] = "right";
    Position["bottom"] = "bottom";
    Position["left"] = "left";
})(Position = exports.Position || (exports.Position = {}));
var HorizontalAlign;
(function (HorizontalAlign) {
    HorizontalAlign["left"] = "left";
    HorizontalAlign["center"] = "center";
    HorizontalAlign["right"] = "right";
})(HorizontalAlign = exports.HorizontalAlign || (exports.HorizontalAlign = {}));
var VerticalAlign;
(function (VerticalAlign) {
    VerticalAlign["top"] = "top";
    VerticalAlign["middle"] = "middle";
    VerticalAlign["bottom"] = "bottom";
})(VerticalAlign = exports.VerticalAlign || (exports.VerticalAlign = {}));
var AxisAlign;
(function (AxisAlign) {
    AxisAlign["start"] = "start";
    AxisAlign["middle"] = "middle";
    AxisAlign["end"] = "end";
})(AxisAlign = exports.AxisAlign || (exports.AxisAlign = {}));
var OwidArticlePublicationContext;
(function (OwidArticlePublicationContext) {
    OwidArticlePublicationContext["unlisted"] = "unlisted";
    OwidArticlePublicationContext["listed"] = "listed";
})(OwidArticlePublicationContext = exports.OwidArticlePublicationContext || (exports.OwidArticlePublicationContext = {}));
var GdocsContentSource;
(function (GdocsContentSource) {
    GdocsContentSource["Internal"] = "internal";
    GdocsContentSource["Gdocs"] = "gdocs";
})(GdocsContentSource = exports.GdocsContentSource || (exports.GdocsContentSource = {}));
var SiteFooterContext;
(function (SiteFooterContext) {
    SiteFooterContext["gdocsPreview"] = "gdocsPreview";
    SiteFooterContext["gdocsArticle"] = "gdocsArticle";
    SiteFooterContext["grapherPage"] = "grapherPage";
    SiteFooterContext["explorerPage"] = "explorerPage";
    SiteFooterContext["default"] = "default";
})(SiteFooterContext = exports.SiteFooterContext || (exports.SiteFooterContext = {}));
