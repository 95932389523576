"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatValue = exports.checkIsVeryShortUnit = void 0;
const d3_format_1 = require("d3-format");
const Util_js_1 = require("./Util.js");
// Used outside this module to figure out if the unit will be joined with the number.
function checkIsVeryShortUnit(unit) {
    return ["%", "$", "£"].includes(unit);
}
exports.checkIsVeryShortUnit = checkIsVeryShortUnit;
function checkIsUnitCurrency(unit) {
    return ["$", "£"].includes(unit);
}
function checkIsUnitPercent(unit) {
    return unit[0] === "%";
}
function getTrim({ trailingZeroes }) {
    return trailingZeroes ? "" : "~";
}
function getSign({ showPlus }) {
    return showPlus ? "+" : "";
}
function getSymbol({ unit }) {
    return checkIsUnitCurrency(unit) ? "$" : "";
}
function getType({ numberAbbreviation, value, unit, }) {
    // f: fixed-point notation (i.e. fixed number of decimal points)
    // s: decimal notation with an SI prefix, rounded to significant digits
    if (checkIsUnitPercent(unit)) {
        return "f";
    }
    if (numberAbbreviation === "long") {
        // do not abbreviate until 1 million
        return Math.abs(value) < 1e6 ? "f" : "s";
    }
    if (numberAbbreviation === "short") {
        // do not abbreviate until 1 thousand
        return Math.abs(value) < 1e3 ? "f" : "s";
    }
    return "f";
}
function getPrecision({ value, numDecimalPlaces, type, }) {
    if (type === "f") {
        return `${numDecimalPlaces}`;
    }
    // when dealing with abbreviated numbers, adjust precision so we get 12.84 million instead of 13 million
    // the modulo one-liner counts the "place columns" of the number, resetting every 3
    // 1 -> 1, 48 -> 2, 981 -> 3, 7222 -> 1
    const numberOfDigits = String(Math.floor(Math.abs(value))).length;
    const precisionPadding = ((numberOfDigits - 1) % 3) + 1;
    // hard-coded 2 decimal places for abbreviated numbers
    return `${precisionPadding + 2}`;
}
function replaceSIPrefixes({ string, numberAbbreviation, }) {
    const prefix = string[string.length - 1];
    const prefixMap = {
        short: {
            k: "k",
            M: "M",
            G: "B",
            T: "T",
            P: "quad",
            E: "quint",
            Z: "sext",
            Y: "sept",
        },
        long: {
            k: "k",
            M: " million",
            G: " billion",
            T: " trillion",
            P: " quadrillion",
            E: " quintillion",
            Z: " sextillion",
            Y: " septillion",
        },
    };
    if (prefixMap[numberAbbreviation][prefix]) {
        return string.replace(prefix, prefixMap[numberAbbreviation][prefix]);
    }
    return string;
}
function postprocessString({ string, numberAbbreviation, spaceBeforeUnit, unit, value, numDecimalPlaces, }) {
    let output = string;
    // handling infinitesimal values
    const tooSmallThreshold = Math.pow(10, -numDecimalPlaces).toPrecision(1);
    if (numberAbbreviation && 0 < value && value < +tooSmallThreshold) {
        output = "<" + output.replace(/0\.?(\d+)?/, tooSmallThreshold);
    }
    if (numberAbbreviation) {
        output = replaceSIPrefixes({
            string: output,
            numberAbbreviation,
        });
    }
    if (unit && !checkIsUnitCurrency(unit)) {
        const appendage = spaceBeforeUnit ? ` ${unit}` : unit;
        output += appendage;
    }
    return output;
}
function formatValue(value, { trailingZeroes = false, unit = "", spaceBeforeUnit = !checkIsUnitPercent(unit), showPlus = false, numDecimalPlaces = 2, numberAbbreviation = "long", }) {
    const formatter = (0, Util_js_1.createFormatter)(unit);
    // Explore how specifiers work here
    // https://observablehq.com/@ikesau/d3-format-interactive-demo
    const specifier = new d3_format_1.FormatSpecifier({
        zero: "0",
        trim: getTrim({ trailingZeroes }),
        sign: getSign({ showPlus }),
        symbol: getSymbol({ unit }),
        comma: ",",
        precision: getPrecision({
            value,
            numDecimalPlaces,
            type: getType({ numberAbbreviation, value, unit }),
        }),
        type: getType({ numberAbbreviation, value, unit }),
    }).toString();
    const formattedString = formatter(specifier)(value);
    const postprocessedString = postprocessString({
        string: formattedString,
        numberAbbreviation,
        spaceBeforeUnit,
        unit,
        value,
        numDecimalPlaces,
    });
    return postprocessedString;
}
exports.formatValue = formatValue;
