"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SynthesizeFruitTableWithStringValues = exports.SynthesizeFruitTableWithNonPositives = exports.SynthesizeFruitTable = exports.SynthesizeGDPTable = exports.SampleColumnSlugs = exports.SynthesizeNonCountryTable = void 0;
const utils_1 = require("@ourworldindata/utils");
const CoreColumnDef_js_1 = require("./CoreColumnDef.js");
const OwidTable_js_1 = require("./OwidTable.js");
const OwidTableConstants_js_1 = require("./OwidTableConstants.js");
const SynthesizeOwidTable = (options, seed = Date.now()) => {
    const finalOptions = Object.assign({ entityNames: [], entityCount: 2, timeRange: [1950, 2020], columnDefs: [] }, options);
    const { entityCount, columnDefs, timeRange, entityNames } = finalOptions;
    const colSlugs = [
        OwidTableConstants_js_1.OwidTableSlugs.entityName,
        OwidTableConstants_js_1.OwidTableSlugs.entityCode,
        OwidTableConstants_js_1.OwidTableSlugs.entityId,
        OwidTableConstants_js_1.OwidTableSlugs.year,
    ].concat(columnDefs.map((col) => col.slug));
    const entities = entityNames.length
        ? entityNames.map((name) => {
            return {
                name,
                code: name.substr(0, 3).toUpperCase(),
            };
        })
        : (0, utils_1.sampleFrom)(utils_1.countries, entityCount, seed);
    const rows = entities.map((entity, index) => {
        let values = columnDefs.map((def) => def.generator());
        return (0, utils_1.range)(timeRange[0], timeRange[1])
            .map((year) => {
            values = columnDefs.map((def, index) => Math.round(values[index] * (1 + def.growthRateGenerator() / 100)));
            return [entity.name, entity.code, index, year, ...values].join(",");
        })
            .join("\n");
    });
    return new OwidTable_js_1.OwidTable(`${colSlugs.join(",")}\n${rows.join("\n")}`, columnDefs);
};
const SynthesizeNonCountryTable = (options, seed = Date.now()) => SynthesizeOwidTable(Object.assign({ entityNames: ["Fire", "Earthquake", "Tornado"], columnDefs: [
        {
            slug: SampleColumnSlugs.Disasters,
            type: CoreColumnDef_js_1.ColumnTypeNames.Integer,
            generator: (0, utils_1.getRandomNumberGenerator)(0, 20, seed),
            growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-50, 50, seed),
        },
    ] }, options), seed);
exports.SynthesizeNonCountryTable = SynthesizeNonCountryTable;
var SampleColumnSlugs;
(function (SampleColumnSlugs) {
    SampleColumnSlugs["Population"] = "Population";
    SampleColumnSlugs["GDP"] = "GDP";
    SampleColumnSlugs["LifeExpectancy"] = "LifeExpectancy";
    SampleColumnSlugs["Fruit"] = "Fruit";
    SampleColumnSlugs["Vegetables"] = "Vegetables";
    SampleColumnSlugs["Disasters"] = "Disasters";
})(SampleColumnSlugs = exports.SampleColumnSlugs || (exports.SampleColumnSlugs = {}));
const SynthesizeGDPTable = (options, seed = Date.now(), display) => SynthesizeOwidTable(Object.assign({ columnDefs: [
        Object.assign(Object.assign({}, SynthSource(SampleColumnSlugs.Population)), { slug: SampleColumnSlugs.Population, type: CoreColumnDef_js_1.ColumnTypeNames.Population, generator: (0, utils_1.getRandomNumberGenerator)(1e7, 1e9, seed), growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-5, 5, seed), display }),
        Object.assign(Object.assign({}, SynthSource(SampleColumnSlugs.GDP)), { slug: SampleColumnSlugs.GDP, type: CoreColumnDef_js_1.ColumnTypeNames.Currency, generator: (0, utils_1.getRandomNumberGenerator)(1e9, 1e12, seed), growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-15, 15, seed), display }),
        Object.assign(Object.assign({}, SynthSource(SampleColumnSlugs.LifeExpectancy)), { slug: SampleColumnSlugs.LifeExpectancy, type: CoreColumnDef_js_1.ColumnTypeNames.Age, generator: (0, utils_1.getRandomNumberGenerator)(60, 90, seed), growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-2, 2, seed), display }),
    ] }, options), seed);
exports.SynthesizeGDPTable = SynthesizeGDPTable;
const SynthSource = (name) => {
    return {
        // id: name.charCodeAt(0) + name.charCodeAt(1) + name.charCodeAt(2),
        sourceName: `${name} Almanac`,
        sourceLink: "http://foo.example",
        dataPublishedBy: `${name} Synthetic Data Team`,
        dataPublisherSource: `${name} Institute`,
        retrievedDate: "1/1/2000",
        additionalInfo: `Downloaded via FTP`,
    };
};
const SynthesizeFruitTable = (options, seed = Date.now()) => SynthesizeOwidTable(Object.assign({ columnDefs: [
        Object.assign(Object.assign({}, SynthSource(SampleColumnSlugs.Fruit)), { slug: SampleColumnSlugs.Fruit, type: CoreColumnDef_js_1.ColumnTypeNames.Numeric, generator: (0, utils_1.getRandomNumberGenerator)(500, 1000, seed), growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-10, 10, seed) }),
        Object.assign(Object.assign({}, SynthSource(SampleColumnSlugs.Vegetables)), { slug: SampleColumnSlugs.Vegetables, type: CoreColumnDef_js_1.ColumnTypeNames.Numeric, generator: (0, utils_1.getRandomNumberGenerator)(400, 1000, seed), growthRateGenerator: (0, utils_1.getRandomNumberGenerator)(-10, 12, seed) }),
    ] }, options), seed);
exports.SynthesizeFruitTable = SynthesizeFruitTable;
const SynthesizeFruitTableWithNonPositives = (options, howManyNonPositives = 20, seed = Date.now()) => {
    const rand = (0, utils_1.getRandomNumberGenerator)(-1000, 0);
    return (0, exports.SynthesizeFruitTable)(options, seed).replaceRandomCells(howManyNonPositives, [SampleColumnSlugs.Fruit, SampleColumnSlugs.Vegetables], undefined, () => rand());
};
exports.SynthesizeFruitTableWithNonPositives = SynthesizeFruitTableWithNonPositives;
const stringValues = ["NA", "inf", "..", "/", "-", "#VALUE!"];
const SynthesizeFruitTableWithStringValues = (options, howMany = 20, seed = Date.now()) => {
    return (0, exports.SynthesizeFruitTable)(options, seed).replaceRandomCells(howMany, [SampleColumnSlugs.Fruit, SampleColumnSlugs.Vegetables], undefined, () => (0, utils_1.sampleFrom)(stringValues, 1, Date.now())[0]);
};
exports.SynthesizeFruitTableWithStringValues = SynthesizeFruitTableWithStringValues;
