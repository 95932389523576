"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverseColumnStore = exports.replaceDef = exports.autodetectColumnDefs = exports.rowsToColumnStore = exports.concatColumnStores = exports.appendRowsToColumnStore = exports.imemo = exports.makeKeyFn = exports.interpolateRowValuesWithTolerance = exports.toleranceInterpolation = exports.linearInterpolation = exports.makeRowFromColumnStore = exports.guessColumnDefFromSlugAndRow = exports.standardizeSlugs = exports.makeAutoTypeFn = exports.truncate = exports.columnStoreToRows = exports.defaultIfErrorValue = exports.isNotErrorValueOrEmptyCell = exports.isNotErrorValue = exports.ErrorValueTypes = exports.MissingValuePlaceholder = exports.ValueTooLow = exports.DivideByZeroError = exports.DroppedForTesting = exports.ErrorValue = exports.BlankOwidTable = exports.OwidTable = exports.ColumnTypeNames = exports.TimeColumn = exports.AbstractCoreColumn = exports.ColumnTypeMap = exports.MissingColumn = exports.StandardOwidColumnDefs = exports.OwidEntityCodeColumnDef = exports.OwidEntityIdColumnDef = exports.OwidEntityNameColumnDef = exports.OwidTableSlugs = exports.JsTypes = exports.TransformType = exports.InputType = exports.SortOrder = exports.SynthesizeFruitTableWithStringValues = exports.SynthesizeFruitTableWithNonPositives = exports.SynthesizeFruitTable = exports.SynthesizeGDPTable = exports.SampleColumnSlugs = exports.SynthesizeNonCountryTable = exports.columnDefinitionsFromDelimited = exports.CoreTable = void 0;
exports.applyTransforms = exports.AvailableTransforms = exports.computeRollingAverage = exports.insertMissingValuePlaceholders = exports.toPercentageColumnDef = exports.getOriginalTimeColumnSlug = exports.makeOriginalTimeSlugFromColumnSlug = exports.timeColumnSlugFromColumnDef = exports.emptyColumnsInFirstRowInDelimited = exports.sortColumnStore = exports.cartesianProduct = exports.trimArray = exports.trimEmptyRows = exports.isCellEmpty = exports.rowsToMatrix = exports.detectDelimiter = exports.parseDelimited = exports.matrixToDelimited = exports.trimMatrix = exports.rowsFromMatrix = exports.Timer = exports.replaceRandomCellsInColumnStore = exports.getDropIndexes = exports.renameColumnStore = void 0;
var CoreTable_js_1 = require("./CoreTable.js");
Object.defineProperty(exports, "CoreTable", { enumerable: true, get: function () { return CoreTable_js_1.CoreTable; } });
Object.defineProperty(exports, "columnDefinitionsFromDelimited", { enumerable: true, get: function () { return CoreTable_js_1.columnDefinitionsFromDelimited; } });
var OwidTableSynthesizers_js_1 = require("./OwidTableSynthesizers.js");
Object.defineProperty(exports, "SynthesizeNonCountryTable", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SynthesizeNonCountryTable; } });
Object.defineProperty(exports, "SampleColumnSlugs", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SampleColumnSlugs; } });
Object.defineProperty(exports, "SynthesizeGDPTable", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SynthesizeGDPTable; } });
Object.defineProperty(exports, "SynthesizeFruitTable", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SynthesizeFruitTable; } });
Object.defineProperty(exports, "SynthesizeFruitTableWithNonPositives", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SynthesizeFruitTableWithNonPositives; } });
Object.defineProperty(exports, "SynthesizeFruitTableWithStringValues", { enumerable: true, get: function () { return OwidTableSynthesizers_js_1.SynthesizeFruitTableWithStringValues; } });
var CoreTableConstants_js_1 = require("./CoreTableConstants.js");
Object.defineProperty(exports, "SortOrder", { enumerable: true, get: function () { return CoreTableConstants_js_1.SortOrder; } });
Object.defineProperty(exports, "InputType", { enumerable: true, get: function () { return CoreTableConstants_js_1.InputType; } });
Object.defineProperty(exports, "TransformType", { enumerable: true, get: function () { return CoreTableConstants_js_1.TransformType; } });
Object.defineProperty(exports, "JsTypes", { enumerable: true, get: function () { return CoreTableConstants_js_1.JsTypes; } });
var OwidTableConstants_js_1 = require("./OwidTableConstants.js");
Object.defineProperty(exports, "OwidTableSlugs", { enumerable: true, get: function () { return OwidTableConstants_js_1.OwidTableSlugs; } });
Object.defineProperty(exports, "OwidEntityNameColumnDef", { enumerable: true, get: function () { return OwidTableConstants_js_1.OwidEntityNameColumnDef; } });
Object.defineProperty(exports, "OwidEntityIdColumnDef", { enumerable: true, get: function () { return OwidTableConstants_js_1.OwidEntityIdColumnDef; } });
Object.defineProperty(exports, "OwidEntityCodeColumnDef", { enumerable: true, get: function () { return OwidTableConstants_js_1.OwidEntityCodeColumnDef; } });
Object.defineProperty(exports, "StandardOwidColumnDefs", { enumerable: true, get: function () { return OwidTableConstants_js_1.StandardOwidColumnDefs; } });
var CoreTableColumns_js_1 = require("./CoreTableColumns.js");
Object.defineProperty(exports, "MissingColumn", { enumerable: true, get: function () { return CoreTableColumns_js_1.MissingColumn; } });
Object.defineProperty(exports, "ColumnTypeMap", { enumerable: true, get: function () { return CoreTableColumns_js_1.ColumnTypeMap; } });
Object.defineProperty(exports, "AbstractCoreColumn", { enumerable: true, get: function () { return CoreTableColumns_js_1.AbstractCoreColumn; } });
Object.defineProperty(exports, "TimeColumn", { enumerable: true, get: function () { return CoreTableColumns_js_1.TimeColumn; } });
var CoreColumnDef_js_1 = require("./CoreColumnDef.js");
Object.defineProperty(exports, "ColumnTypeNames", { enumerable: true, get: function () { return CoreColumnDef_js_1.ColumnTypeNames; } });
var OwidTable_js_1 = require("./OwidTable.js");
Object.defineProperty(exports, "OwidTable", { enumerable: true, get: function () { return OwidTable_js_1.OwidTable; } });
Object.defineProperty(exports, "BlankOwidTable", { enumerable: true, get: function () { return OwidTable_js_1.BlankOwidTable; } });
var ErrorValues_js_1 = require("./ErrorValues.js");
Object.defineProperty(exports, "ErrorValue", { enumerable: true, get: function () { return ErrorValues_js_1.ErrorValue; } });
Object.defineProperty(exports, "DroppedForTesting", { enumerable: true, get: function () { return ErrorValues_js_1.DroppedForTesting; } });
Object.defineProperty(exports, "DivideByZeroError", { enumerable: true, get: function () { return ErrorValues_js_1.DivideByZeroError; } });
Object.defineProperty(exports, "ValueTooLow", { enumerable: true, get: function () { return ErrorValues_js_1.ValueTooLow; } });
Object.defineProperty(exports, "MissingValuePlaceholder", { enumerable: true, get: function () { return ErrorValues_js_1.MissingValuePlaceholder; } });
Object.defineProperty(exports, "ErrorValueTypes", { enumerable: true, get: function () { return ErrorValues_js_1.ErrorValueTypes; } });
Object.defineProperty(exports, "isNotErrorValue", { enumerable: true, get: function () { return ErrorValues_js_1.isNotErrorValue; } });
Object.defineProperty(exports, "isNotErrorValueOrEmptyCell", { enumerable: true, get: function () { return ErrorValues_js_1.isNotErrorValueOrEmptyCell; } });
Object.defineProperty(exports, "defaultIfErrorValue", { enumerable: true, get: function () { return ErrorValues_js_1.defaultIfErrorValue; } });
var CoreTableUtils_js_1 = require("./CoreTableUtils.js");
Object.defineProperty(exports, "columnStoreToRows", { enumerable: true, get: function () { return CoreTableUtils_js_1.columnStoreToRows; } });
Object.defineProperty(exports, "truncate", { enumerable: true, get: function () { return CoreTableUtils_js_1.truncate; } });
Object.defineProperty(exports, "makeAutoTypeFn", { enumerable: true, get: function () { return CoreTableUtils_js_1.makeAutoTypeFn; } });
Object.defineProperty(exports, "standardizeSlugs", { enumerable: true, get: function () { return CoreTableUtils_js_1.standardizeSlugs; } });
Object.defineProperty(exports, "guessColumnDefFromSlugAndRow", { enumerable: true, get: function () { return CoreTableUtils_js_1.guessColumnDefFromSlugAndRow; } });
Object.defineProperty(exports, "makeRowFromColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.makeRowFromColumnStore; } });
Object.defineProperty(exports, "linearInterpolation", { enumerable: true, get: function () { return CoreTableUtils_js_1.linearInterpolation; } });
Object.defineProperty(exports, "toleranceInterpolation", { enumerable: true, get: function () { return CoreTableUtils_js_1.toleranceInterpolation; } });
Object.defineProperty(exports, "interpolateRowValuesWithTolerance", { enumerable: true, get: function () { return CoreTableUtils_js_1.interpolateRowValuesWithTolerance; } });
Object.defineProperty(exports, "makeKeyFn", { enumerable: true, get: function () { return CoreTableUtils_js_1.makeKeyFn; } });
Object.defineProperty(exports, "imemo", { enumerable: true, get: function () { return CoreTableUtils_js_1.imemo; } });
Object.defineProperty(exports, "appendRowsToColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.appendRowsToColumnStore; } });
Object.defineProperty(exports, "concatColumnStores", { enumerable: true, get: function () { return CoreTableUtils_js_1.concatColumnStores; } });
Object.defineProperty(exports, "rowsToColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.rowsToColumnStore; } });
Object.defineProperty(exports, "autodetectColumnDefs", { enumerable: true, get: function () { return CoreTableUtils_js_1.autodetectColumnDefs; } });
Object.defineProperty(exports, "replaceDef", { enumerable: true, get: function () { return CoreTableUtils_js_1.replaceDef; } });
Object.defineProperty(exports, "reverseColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.reverseColumnStore; } });
Object.defineProperty(exports, "renameColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.renameColumnStore; } });
Object.defineProperty(exports, "getDropIndexes", { enumerable: true, get: function () { return CoreTableUtils_js_1.getDropIndexes; } });
Object.defineProperty(exports, "replaceRandomCellsInColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.replaceRandomCellsInColumnStore; } });
Object.defineProperty(exports, "Timer", { enumerable: true, get: function () { return CoreTableUtils_js_1.Timer; } });
Object.defineProperty(exports, "rowsFromMatrix", { enumerable: true, get: function () { return CoreTableUtils_js_1.rowsFromMatrix; } });
Object.defineProperty(exports, "trimMatrix", { enumerable: true, get: function () { return CoreTableUtils_js_1.trimMatrix; } });
Object.defineProperty(exports, "matrixToDelimited", { enumerable: true, get: function () { return CoreTableUtils_js_1.matrixToDelimited; } });
Object.defineProperty(exports, "parseDelimited", { enumerable: true, get: function () { return CoreTableUtils_js_1.parseDelimited; } });
Object.defineProperty(exports, "detectDelimiter", { enumerable: true, get: function () { return CoreTableUtils_js_1.detectDelimiter; } });
Object.defineProperty(exports, "rowsToMatrix", { enumerable: true, get: function () { return CoreTableUtils_js_1.rowsToMatrix; } });
Object.defineProperty(exports, "isCellEmpty", { enumerable: true, get: function () { return CoreTableUtils_js_1.isCellEmpty; } });
Object.defineProperty(exports, "trimEmptyRows", { enumerable: true, get: function () { return CoreTableUtils_js_1.trimEmptyRows; } });
Object.defineProperty(exports, "trimArray", { enumerable: true, get: function () { return CoreTableUtils_js_1.trimArray; } });
Object.defineProperty(exports, "cartesianProduct", { enumerable: true, get: function () { return CoreTableUtils_js_1.cartesianProduct; } });
Object.defineProperty(exports, "sortColumnStore", { enumerable: true, get: function () { return CoreTableUtils_js_1.sortColumnStore; } });
Object.defineProperty(exports, "emptyColumnsInFirstRowInDelimited", { enumerable: true, get: function () { return CoreTableUtils_js_1.emptyColumnsInFirstRowInDelimited; } });
var OwidTableUtil_js_1 = require("./OwidTableUtil.js");
Object.defineProperty(exports, "timeColumnSlugFromColumnDef", { enumerable: true, get: function () { return OwidTableUtil_js_1.timeColumnSlugFromColumnDef; } });
Object.defineProperty(exports, "makeOriginalTimeSlugFromColumnSlug", { enumerable: true, get: function () { return OwidTableUtil_js_1.makeOriginalTimeSlugFromColumnSlug; } });
Object.defineProperty(exports, "getOriginalTimeColumnSlug", { enumerable: true, get: function () { return OwidTableUtil_js_1.getOriginalTimeColumnSlug; } });
Object.defineProperty(exports, "toPercentageColumnDef", { enumerable: true, get: function () { return OwidTableUtil_js_1.toPercentageColumnDef; } });
var Transforms_js_1 = require("./Transforms.js");
Object.defineProperty(exports, "insertMissingValuePlaceholders", { enumerable: true, get: function () { return Transforms_js_1.insertMissingValuePlaceholders; } });
Object.defineProperty(exports, "computeRollingAverage", { enumerable: true, get: function () { return Transforms_js_1.computeRollingAverage; } });
Object.defineProperty(exports, "AvailableTransforms", { enumerable: true, get: function () { return Transforms_js_1.AvailableTransforms; } });
Object.defineProperty(exports, "applyTransforms", { enumerable: true, get: function () { return Transforms_js_1.applyTransforms; } });
