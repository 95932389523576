"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStylesForTargetHeight = void 0;
const getStylesForTargetHeight = (targetHeight) => {
    // Taken from https://github.com/JedWatson/react-select/issues/1322#issuecomment-591189551
    return {
        control: (provided) => (Object.assign(Object.assign({}, provided), { minHeight: "initial" })),
        valueContainer: (provided) => (Object.assign(Object.assign({}, provided), { height: `${targetHeight - 1 - 1}px`, padding: "0 4px", flexWrap: "nowrap" })),
        clearIndicator: (provided) => (Object.assign(Object.assign({}, provided), { padding: `${(targetHeight - 20 - 1 - 1) / 2}px` })),
        dropdownIndicator: (provided) => (Object.assign(Object.assign({}, provided), { padding: `${(targetHeight - 20 - 1 - 1) / 2}px` })),
        option: (provided) => (Object.assign(Object.assign({}, provided), { paddingTop: "5px", paddingBottom: "5px" })),
        menu: (provided) => (Object.assign(Object.assign({}, provided), { zIndex: 10000 })),
    };
};
exports.getStylesForTargetHeight = getStylesForTargetHeight;
