"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnTypeNames = void 0;
var ColumnTypeNames;
(function (ColumnTypeNames) {
    ColumnTypeNames["NumberOrString"] = "NumberOrString";
    ColumnTypeNames["Numeric"] = "Numeric";
    ColumnTypeNames["String"] = "String";
    ColumnTypeNames["Region"] = "Region";
    ColumnTypeNames["SeriesAnnotation"] = "SeriesAnnotation";
    ColumnTypeNames["Categorical"] = "Categorical";
    ColumnTypeNames["Continent"] = "Continent";
    ColumnTypeNames["EntityName"] = "EntityName";
    ColumnTypeNames["EntityId"] = "EntityId";
    ColumnTypeNames["EntityCode"] = "EntityCode";
    ColumnTypeNames["Boolean"] = "Boolean";
    ColumnTypeNames["Currency"] = "Currency";
    ColumnTypeNames["Percentage"] = "Percentage";
    ColumnTypeNames["RelativePercentage"] = "RelativePercentage";
    ColumnTypeNames["DecimalPercentage"] = "DecimalPercentage";
    ColumnTypeNames["Integer"] = "Integer";
    ColumnTypeNames["PercentChangeOverTime"] = "PercentChangeOverTime";
    ColumnTypeNames["Ratio"] = "Ratio";
    ColumnTypeNames["Year"] = "Year";
    ColumnTypeNames["Day"] = "Day";
    ColumnTypeNames["Date"] = "Date";
    ColumnTypeNames["Color"] = "Color";
    ColumnTypeNames["Population"] = "Population";
    ColumnTypeNames["PopulationDensity"] = "PopulationDensity";
    ColumnTypeNames["Age"] = "Age";
    ColumnTypeNames["Quarter"] = "Quarter";
})(ColumnTypeNames = exports.ColumnTypeNames || (exports.ColumnTypeNames = {}));
