"use strict";
// todo: remove file
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwidVariableDisplayConfig = void 0;
const mobx_1 = require("mobx");
const Persistable_js_1 = require("./persistable/Persistable.js");
class OwidVariableDisplayConfigDefaults {
    constructor() {
        this.name = undefined;
        this.unit = undefined;
        this.shortUnit = undefined;
        this.isProjection = undefined;
        this.conversionFactor = undefined;
        this.numDecimalPlaces = undefined;
        this.tolerance = undefined;
        this.yearIsDay = undefined;
        this.zeroDay = undefined;
        this.entityAnnotationsMap = undefined;
        this.includeInTable = true;
        this.color = undefined;
    }
}
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "name", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "unit", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "shortUnit", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "isProjection", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "conversionFactor", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "numDecimalPlaces", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "tolerance", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "yearIsDay", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "zeroDay", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "entityAnnotationsMap", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "includeInTable", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "tableDisplay", void 0);
__decorate([
    mobx_1.observable
], OwidVariableDisplayConfigDefaults.prototype, "color", void 0);
class OwidVariableDisplayConfig extends OwidVariableDisplayConfigDefaults {
    updateFromObject(obj) {
        if (obj)
            (0, Persistable_js_1.updatePersistables)(this, obj);
    }
    toObject() {
        return (0, Persistable_js_1.deleteRuntimeAndUnchangedProps)((0, Persistable_js_1.objectWithPersistablesToObject)(this), new OwidVariableDisplayConfigDefaults());
    }
    constructor(obj) {
        super();
        if (obj)
            this.updateFromObject(obj);
    }
}
exports.OwidVariableDisplayConfig = OwidVariableDisplayConfig;
// export interface OwidVariablesAndEntityKey {
//     variables: {
//         [id: string]: OwidVariableWithDataAndSource
//     }
//     entityKey: OwidEntityKey
// }
