"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractFieldDescriptionsFromSchema = exports.FieldType = exports.EditorOption = void 0;
const Util_js_1 = require("./Util.js");
const patchHelper_js_1 = require("./patchHelper.js");
var EditorOption;
(function (EditorOption) {
    EditorOption["textfield"] = "textfield";
    EditorOption["textarea"] = "textarea";
    EditorOption["dropdown"] = "dropdown";
    EditorOption["numeric"] = "numeric";
    EditorOption["numericWithLatestEarliest"] = "numericWithLatestEarliest";
    EditorOption["checkbox"] = "checkbox";
    EditorOption["colorEditor"] = "colorEditor";
    EditorOption["mappingEditor"] = "mappingEditor";
    EditorOption["primitiveListEditor"] = "primitiveListEditor";
})(EditorOption = exports.EditorOption || (exports.EditorOption = {}));
var FieldType;
(function (FieldType) {
    FieldType["number"] = "number";
    FieldType["string"] = "string";
    FieldType["boolean"] = "boolean";
    FieldType["integer"] = "integer";
    FieldType["complex"] = "complex";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
function isPlainTypeString(item) {
    return (item === "number" ||
        item === "string" ||
        item === "boolean" ||
        item === "integer");
}
function isPlainTypeOrArrayOfPlainType(type) {
    return (isPlainTypeString(type) ||
        ((0, Util_js_1.isArray)(type) && type.every(isPlainTypeStringOrNull)));
}
function isPlainTypeStringOrNull(item) {
    return isPlainTypeString(item) || item === "null";
}
function typeIsGivenOrNull(typeToTest, type) {
    // We often have a type that is an array of a given type and null. This helper
    // function returns true if the type is exactly the given type or an array of this and null
    if (typeof typeToTest === "string")
        return typeToTest === type;
    return typeToTest.every((item) => item === type || item === "null");
}
function getEditorOptionForType(type, enumOptions) {
    if (typeIsGivenOrNull(type, "number"))
        return EditorOption.numeric;
    else if (typeIsGivenOrNull(type, "string") && enumOptions)
        return EditorOption.dropdown;
    else if (typeIsGivenOrNull(type, "string"))
        return EditorOption.textfield;
    else if (typeIsGivenOrNull(type, "boolean"))
        return EditorOption.checkbox;
    else if (typeIsGivenOrNull(type, "integer"))
        return EditorOption.numeric;
    else if ((0, Util_js_1.isArray)(type)) {
        // the following line is aspecial case hack for fields that are usually numeric but can have a
        // special string like "latest"
        if (type[0] === "number" && type[1] === "string")
            return EditorOption.numericWithLatestEarliest;
        else
            return EditorOption.textfield;
    }
    else if (type === "array")
        return EditorOption.primitiveListEditor;
    else
        return EditorOption.textfield;
}
/** This function takes a json schema that has been read and converted from json to a
    JS object and processes it into a list of FieldDescriptions. A FieldDescription
    contains just the information we will need for creating the columns for the Bulk FASTT
    editor.

    On a high level, what we do here is walk the recursive document structure of the json
    schema and look at every level. If a property "type" exists and it is object then we
    want to recurse over the properties (we are usually only interested in properties in
    leaf position in the object graph because these are the ones we want to map to a single
    spreadsheet cell). If the type is a primitive then we just create a FieldDescription entry.

    There are some complications, for example:
        * type can be an array to express a union of null | string
        * anyof creates a fork that has to be combined into a single entry
 *
 */
function extractSchemaRecursive(schema, pointer, items) {
    var _a, _b, _c, _d, _e;
    // We shouldn't encounter primitives in the schema itself (since we recurse
    // only into expected structures which are almost always objects)
    if ((0, Util_js_1.isNull)(schema) ||
        (0, Util_js_1.isUndefined)(schema) ||
        (0, Util_js_1.isNumber)(schema) ||
        (0, Util_js_1.isString)(schema) ||
        (0, Util_js_1.isBoolean)(schema) ||
        (0, Util_js_1.isArray)(schema)) {
        console.error("shouldn't come here?", pointer);
        return;
    }
    else if ((0, Util_js_1.checkIsPlainObjectWithGuard)(schema)) {
        // If the current schema fragment describes a normal object
        // then do not emit anything directly and recurse over the
        // described properties
        if (schema.hasOwnProperty("type") &&
            schema.type === "object" &&
            schema.hasOwnProperty("properties") &&
            (0, Util_js_1.checkIsPlainObjectWithGuard)(schema.properties)) {
            // Color scales are complex objects that are treated as opaque objects with a special
            // rich editor. We identify them by the property they are stored as. If we have a color
            // scale, push a single FieldDescription for it with the editor set accordingly.
            // Otherwise we have a normal object and we recurse.
            if (pointer.endsWith("colorScale")) {
                items.push({
                    type: FieldType.complex,
                    getter: (0, patchHelper_js_1.compileGetValueFunction)(pointer),
                    pointer: pointer,
                    default: undefined,
                    editor: EditorOption.colorEditor,
                    enumOptions: undefined,
                    description: (_a = schema.description) !== null && _a !== void 0 ? _a : "",
                });
            }
            else
                for (const key of Object.keys(schema.properties)) {
                    const newPath = `${pointer}/${key}`;
                    extractSchemaRecursive(schema.properties[key], newPath, items);
                }
        }
        else if (
        // if we have an object that uses patternProperties to
        // describe arbitrary properties then we special case
        // if all such properties are of primitive type. In our
        // schema we use such constructs for mapping arbitrary
        // country names to colors for example where the
        // paternProperties is ".*" and the type of those
        // is string (interpreted as a color)
        // We yield something like this as a single entry
        schema.hasOwnProperty("type") &&
            schema.type === "object" &&
            schema.hasOwnProperty("patternProperties") &&
            Object.values(schema.patternProperties).every((item) => (0, Util_js_1.checkIsPlainObjectWithGuard)(item) &&
                item.hasOwnProperty("type") &&
                isPlainTypeString(item.type))) {
            items.push({
                type: schema.type,
                getter: (0, patchHelper_js_1.compileGetValueFunction)(pointer),
                pointer: pointer,
                default: schema.default,
                editor: EditorOption.mappingEditor,
                enumOptions: schema.enum,
                description: (_b = schema.description) !== null && _b !== void 0 ? _b : "",
            });
        }
        else if (schema.type === "array") {
            // If an array contains only primitive values then we want to
            // edit this in one editor session and then replace the entire
            // array
            if (isPlainTypeOrArrayOfPlainType(schema.items.type)) {
                items.push({
                    type: schema.type,
                    getter: (0, patchHelper_js_1.compileGetValueFunction)(pointer),
                    pointer: pointer,
                    default: schema.default,
                    editor: EditorOption.primitiveListEditor,
                    enumOptions: schema.enum,
                    description: (_c = schema.description) !== null && _c !== void 0 ? _c : "",
                });
            }
            // If the array contains an object then things are more complicated -
            // for dimension we have a special case where it only makes sense to
            // talk about a single dimension. For now if we have an object we
            // set the json pointer to the first element and
            else {
                const newPath = `${pointer}/0`;
                extractSchemaRecursive(schema.items, newPath, items);
            }
        }
        else if (isPlainTypeOrArrayOfPlainType(schema.type)) {
            // If the object describes a primitive type or is
            // an array of only primitive types or null (e.g.
            // because the type is ["string", "null"]) then
            // we yield a single element
            items.push({
                type: schema.type,
                getter: (0, patchHelper_js_1.compileGetValueFunction)(pointer),
                pointer: pointer,
                default: schema.default,
                editor: getEditorOptionForType(schema.type, schema.enum),
                enumOptions: schema.enum,
                description: (_d = schema.description) !== null && _d !== void 0 ? _d : "",
            });
        }
        else if (
        // If we have a oneOf description then we need to
        // check if all of the cases have a type field with
        // a primitive type. If so we collect all the types
        // and yield a single FieldDefinition with the merged
        // type
        schema.hasOwnProperty("oneOf") &&
            (0, Util_js_1.isArray)(schema.oneOf) &&
            schema.oneOf.map((item) => item.type).every(isPlainTypeStringOrNull)) {
            const types = schema.oneOf.map((item) => item.type);
            items.push({
                type: types,
                getter: (0, patchHelper_js_1.compileGetValueFunction)(pointer),
                pointer: pointer,
                default: schema.default,
                editor: getEditorOptionForType(types, undefined),
                description: (_e = schema.description) !== null && _e !== void 0 ? _e : "",
            });
        }
        else {
            console.error("Unexpected type/object", [schema, pointer]);
        }
    }
    else {
        console.error("Unexpected type/object", pointer);
    }
}
function recursiveDereference(schema, defs) {
    if (schema !== null &&
        schema !== undefined &&
        (0, Util_js_1.checkIsPlainObjectWithGuard)(schema)) {
        if (schema.hasOwnProperty("$ref")) {
            const ref = schema["$ref"];
            const localPrefix = "#/$defs/";
            if (!ref.startsWith(localPrefix))
                throw "Only local refs are supported at the moment!";
            const refName = ref.substring(localPrefix.length);
            if (!defs.hasOwnProperty(refName)) {
                console.error("Reference not found", refName);
                return schema;
            }
            else
                return defs[refName]; // Note: we are not using recursive dereferencing, i.e. if there are refs in the $defs section we don't resolve them here
        }
        else {
            return (0, Util_js_1.mapValues)(schema, (val) => recursiveDereference(val, defs));
        }
    }
    else
        return schema;
}
function dereference(schema) {
    if (!schema.hasOwnProperty("$defs"))
        return;
    const defs = schema["$defs"];
    const dereferenced = recursiveDereference(schema, defs);
    const newSchema = (0, Util_js_1.omit)(dereferenced, ["$defs"]);
    return newSchema;
}
function extractFieldDescriptionsFromSchema(schema) {
    if ((0, Util_js_1.checkIsPlainObjectWithGuard)(schema)) {
        const dereferenced = dereference(schema);
        const fieldDescriptions = [];
        extractSchemaRecursive(dereferenced, "", fieldDescriptions);
        return fieldDescriptions;
    }
    else
        throw new Error("Schema was not an object!");
}
exports.extractFieldDescriptionsFromSchema = extractFieldDescriptionsFromSchema;
