"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartBulkUpdateAllowedColumnNamesAndTypes = exports.variableAnnotationAllowedColumnNamesAndTypes = exports.WHITELISTED_SQL_COLUMN_NAMES = void 0;
const SqlFilterSExpression_js_1 = require("./SqlFilterSExpression.js");
var WHITELISTED_SQL_COLUMN_NAMES;
(function (WHITELISTED_SQL_COLUMN_NAMES) {
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_VARIABLE_ID"] = "variables.id";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_VARIABLE_NAME"] = "variables.name";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_DATASET_NAME"] = "datasets.name";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_NAMESPACE_NAME"] = "namespaces.name";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_VARIABLE_DESCRIPTION"] = "variables.description";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_VARIABLE_CREATED_AT"] = "variables.createdAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_VARIABLE_UPDATED_AT"] = "variables.updatedAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_ID"] = "charts.id";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_CREATED_AT"] = "charts.createdAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_UPDATED_AT"] = "charts.updatedAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_LAST_EDITED_AT"] = "charts.lastEditedAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_PUBLISHED_AT"] = "charts.publishedAt";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_LAST_EDITED_BY_USER"] = "editedByUser.fullName";
    WHITELISTED_SQL_COLUMN_NAMES["SQL_COLUMN_NAME_CHART_PUBLISHED_BY_USER"] = "publishedByUser.fullName";
})(WHITELISTED_SQL_COLUMN_NAMES = exports.WHITELISTED_SQL_COLUMN_NAMES || (exports.WHITELISTED_SQL_COLUMN_NAMES = {}));
exports.variableAnnotationAllowedColumnNamesAndTypes = new Map([
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_VARIABLE_ID,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_VARIABLE_NAME,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_DATASET_NAME,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_NAMESPACE_NAME,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_VARIABLE_DESCRIPTION,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_VARIABLE_CREATED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_VARIABLE_UPDATED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
]);
exports.chartBulkUpdateAllowedColumnNamesAndTypes = new Map([
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_ID,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_CREATED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_UPDATED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_LAST_EDITED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_PUBLISHED_AT,
        SqlFilterSExpression_js_1.ExpressionType.numeric,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_LAST_EDITED_BY_USER,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
    [
        WHITELISTED_SQL_COLUMN_NAMES.SQL_COLUMN_NAME_CHART_PUBLISHED_BY_USER,
        SqlFilterSExpression_js_1.ExpressionType.string,
    ],
]);
